import React from "react";
import GeneralInfoForm from "../components/GeneralInfoForm";

const GeneralInfoPage = () => {
  return (
    <div className="h-full bg-white">
      <GeneralInfoForm />
    </div>
  );
};

export default GeneralInfoPage;
