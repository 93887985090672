import React from 'react';
import SpecificQuestion from '../components/SpecificQuestion';

const SpecificQuestionPage = () => {
  return (
    <div className="h-full bg-white">
      <SpecificQuestion />
    </div>
  );
};

export default SpecificQuestionPage;
