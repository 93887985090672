import React from "react";
import { Navigate } from "react-router-dom";
import { useUser } from "../context/UserContext";
import dentnetLogo from "../../src/components/assets/dentnet.png";

const ProtectedRoute = ({ children, adminRequired }) => {
  const { user, isLoading, isError } = useUser();
  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <img src={dentnetLogo} alt="Lade..." />
      </div>
    );
  }
  if (isError) {
    return <Navigate to="/error" replace />;
  }
  if (!user) {
    return <Navigate to="/login" replace />;
  }
  if (adminRequired && user.role !== "admin") {
    return <Navigate to="/dashboard" replace />;
  }
  if (adminRequired && user.role === "admin") {
    return <Navigate to="/admin" replace />;
  }
  return children;
};

export default ProtectedRoute;
