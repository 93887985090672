import React from 'react';
import GeneralInput from '../../GeneralInput';
import SectionHeadline from '../ui/sectionHeadline';

const ServiceForm = ({ data, updateData }) => {
  const handleInputChange = (id, key, value) => {
    updateData(id, key, value);
  };

  return (
    <div className="p-4">
      <SectionHeadline sectionName="Hotline" />
      <GeneralInput
        id="hotline_1"
        type="select"
        label="Servicetelefon 24/7"
        options={[
          { label: 'Ja', value: 'yes' },
          { label: 'Nein', value: 'no' },
        ]}
        value={data?.hotline_1?.value || ''}
        onChange={(value) => handleInputChange('hotline_1', 'value', value)}
      />

      <SectionHeadline sectionName="Telemedicine" />
      <GeneralInput
        id="telemedicine_1"
        type="select"
        label="Tele-Medizin"
        options={[
          { label: 'Ja', value: 'yes' },
          { label: 'Nein', value: 'no' },
        ]}
        value={data?.telemedicine_1?.value || ''}
        onChange={(value) => handleInputChange('telemedicine_1', 'value', value)}
      />
    </div>
  );
};

export default ServiceForm;
