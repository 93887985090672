import React, { useState } from 'react';
import GeneralInput from './GeneralInput';

const SampleForm = () => {
    const [textInputValue, setTextInputValue] = useState('');
    const [descriptionValue, setDescriptionValue] = useState('');
    const [selectValue, setSelectValue] = useState('');
    const [multiSelectValue, setMultiSelectValue] = useState([]);
    const [fileValue, setFileValue] = useState('');
    const [radioValue, setRadioValue] = useState('');  // State for the new radio input

    // Additional state for conditional inputs
    const [conditionalInputOneValue, setConditionalInputOneValue] = useState('');
    const [conditionalInputTwoValue, setConditionalInputTwoValue] = useState('');

    return (
        <div className="p-4 space-y-8">
            <h2 className="text-3xl font-bold text-center mb-8">Sample Form</h2>

            <GeneralInput
                id="textInput"
                type="text"
                label="Text Input"
                placeholder="Enter text"
                onChange={setTextInputValue}
                value={textInputValue}
            />

            <GeneralInput
                id="descriptionInput"
                type="textarea"
                label="Description"
                placeholder="Enter description"
                onChange={setDescriptionValue}
                value={descriptionValue}
            />

            <GeneralInput
                id="singleSelectInput"
                type="select"
                label="Single Select"
                options={[{ label: 'Option 1', value: 'option1' }, { label: 'Option 2', value: 'option2' }, { label: 'Option 3', value: 'option3' }]}
                onChange={setSelectValue}
                value={selectValue}
            />

            <GeneralInput
                id="multiSelectInput"
                type="multi-select"
                label="Multi-Select"
                options={[{ label: 'Option A', value: 'optionA' }, { label: 'Option B', value: 'optionB' }, { label: 'Option C', value: 'optionC' }]}
                onChange={setMultiSelectValue}
                value={multiSelectValue}
            />

            <GeneralInput
                id="fileInput"
                type="file"
                label="Upload File"
                onChange={setFileValue}
                value={fileValue}
            />

            <GeneralInput
                id="radioInput"
                type="radio"
                label="Radio Input"
                options={[{ label: 'Yes', value: 'yes' }, { label: 'No', value: 'no' }]}
                onChange={setRadioValue}
                value={radioValue}
            />

            {/* Conditional inputs */}
            <GeneralInput
                id="conditionalInputOne"
                type="conditional-radio-one"
                label="Conditional Radio One"
                options={[{ label: 'Yes', value: 'yes' }, { label: 'No', value: 'no' }]}
                onChange={setConditionalInputOneValue}
                value={conditionalInputOneValue}
            />

            <GeneralInput
                id="conditionalInputTwo"
                type="conditional-radio-two"
                label="Conditional Radio Two"
                options={[{ label: 'Yes', value: 'yes' }, { label: 'No', value: 'no' }]}
                onChange={setConditionalInputTwoValue}
                value={conditionalInputTwoValue}
            />
        </div>
    );
};

export default SampleForm;
