import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Login from './Pages/auth/Login';
// import Registration from './Pages/auth/Registration';
import Dashboard from './Pages/Dashboard';
import SampleForm from './components/SampleForm';
import ApplicationPage from './Pages/ApplicationPage';
import GeneralInfoPage from './Pages/GeneralInfoPage';
import Profile from './components/Profile';
import SpecificQuestionPage from './Pages/SpecificQuestionPage';
import Admin from './Pages/Admin';
import Navbar from './Pages/Layout/Navbar'; 
import { UserProvider } from './context/UserContext';
import ProtectedRoute from './components/ProtectedRoute';

function AppWrapper() {
  return (
    <UserProvider>
      <Navbar />
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/sample" element={<SampleForm />} />
        <Route path="/dashboard" element={<ProtectedRoute adminRequired={false}><Dashboard /></ProtectedRoute>}>
          <Route path="application" element={<ApplicationPage />} />
          <Route path="general-info" element={<GeneralInfoPage />} />
          <Route path="profile" element={<Profile />} />
          <Route path="specific-questions" element={<SpecificQuestionPage />} />
          <Route index element={<Navigate replace to="general-info" />} />
        </Route>
        <Route path="/admin" element={<ProtectedRoute adminRequired={true}><Admin /></ProtectedRoute>} />
        <Route path="/" element={<Navigate replace to="/login" />} />
      </Routes>
    </UserProvider>
  );
}

function App() {
  return (
    <Router>
      <AppWrapper />
    </Router>
  );
}

export default App;
