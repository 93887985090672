import React  from "react";
import Admin from "../Pages/Admin";
const Profile2 = () => {
  return (
    <div className="h-full flex flex-col bg-gray-100 light:bg-gray-700 shadow-xl overflow-y-scroll">
      <Admin/>
    </div>
  );
};

export default Profile2;
