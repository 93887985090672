import React, { useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import GeneralInput from './GeneralInput';
import { UserContext } from '../context/UserContext';

const GeneralInfoForm = () => {
  const { user, logout } = useContext(UserContext);
  const [formData, setFormData] = useState({
    amount: "",
    regions: [],
    healthInsurance: "",
    file: null,
  });
  const [logoPreview, setLogoPreview] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [regionsOptions, setRegionsOptions] = useState([]);
  const [fetching, setFetching] = useState(true);
  const [existingDataId, setExistingDataId] = useState(null);

  useEffect(() => {
    const germanStates = [
      "Baden-Württemberg", "Bayern", "Berlin", "Brandenburg", "Bremen",
      "Hamburg", "Hessen", "Mecklenburg-Vorpommern", "Niedersachsen",
      "Nordrhein-Westfalen", "Rheinland-Pfalz", "Saarland", "Sachsen",
      "Sachsen-Anhalt", "Schleswig-Holstein", "Thüringen"
    ];

    setRegionsOptions(germanStates.map(state => ({ label: state, value: state })));

    if (user) {
      fetchGeneralData();
    } else {
      setFormData(prevFormData => ({
        ...prevFormData,
        regions: germanStates
      }));
      setFetching(false);
    }
  }, [user]);

  const fetchGeneralData = async () => {
    setFetching(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/general`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });

      const { generalData } = response.data;
      if (generalData) {
        setExistingDataId(generalData._id);
        setFormData({
          amount: generalData.amount.toString().replace(".", ",") || "",
          regions: generalData.operatingRegions || [],
          healthInsurance: generalData.isClosedHealthInsurance ? "yes" : "no",
          file: null,
        });
        if (generalData.logo) {
          setLogoPreview(`${process.env.REACT_APP_API_BASE_URL}${generalData.logo}`);
        }
      }
    } catch (error) {
      console.error("Error fetching general data:", error.response || error);
      if (error.response && error.response.status === 401) {
        toast.error("Session expired. Please log in again.");
        logout();
      } else {
        toast.error(`Fehler beim Laden der allgemeinen Daten: ${error.response?.data?.message || error.message}`);
      }
    } finally {
      setFetching(false);
    }
  };

  const handleInputChange = (name, value) => {
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    if (name === 'file') {
      setLogoPreview(URL.createObjectURL(value));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const { amount, regions, healthInsurance, file } = formData;

      const formDataToSend = new FormData();
      formDataToSend.append('amount', parseFloat(amount.replace(",", ".")));
      formDataToSend.append('operatingRegions', JSON.stringify(regions));
      formDataToSend.append('isClosedHealthInsurance', healthInsurance === "yes");
      if (file) {
        formDataToSend.append('logo', file);
      }

      const url = `${process.env.REACT_APP_API_BASE_URL}/general`;
      const method = existingDataId ? 'put' : 'post';

      const response = await axios({
        method,
        url,
        data: formDataToSend,
        headers: {
          Authorization: `Bearer ${user.token}`,
          'Content-Type': 'multipart/form-data',
        },
      });

      toast.success(existingDataId 
        ? "Generelle Informationen erfolgreich aktualisiert!" 
        : "Generelle Informationen erfolgreich erstellt!");

      fetchGeneralData();

    } catch (error) {
      console.error("Error handling form submission:", error.response || error);
      if (error.response && error.response.status === 401) {
        toast.error("Session expired. Please log in again.");
        logout();
      } else {
        toast.error(`Fehler beim Übermitteln der Formulardaten: ${error.response?.data?.message || error.message}`);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="px-8 pt-6 pb-8 mb-4 bg-white rounded-lg shadow-md">
      <ToastContainer position="top-right" autoClose={5000} />
      <h3 className="text-2xl text-center text-gray-800">Allgemeine Fragen</h3>
      {fetching ? (
        <p>Loading...</p>
      ) : (
        <form onSubmit={handleSubmit}>
          <GeneralInput
            id="amount"
            type="text"
            label="Zusatzbeitrag"
            placeholder="Zusatzbeitrag in %"
            value={formData.amount}
            onChange={(value) => handleInputChange("amount", value)}
          />
          <GeneralInput
            id="regions"
            type="multi-select"
            label="Abgedeckte Regionen"
            options={regionsOptions}
            value={formData.regions}
            onChange={(value) => handleInputChange("regions", value)}
          />
          <GeneralInput
            id="healthInsurance"
            type="radio"
            label="Geschlossene Krankenversicherung"
            options={[
              { label: "Ja", value: "yes" },
              { label: "Nein", value: "no" },
            ]}
            value={formData.healthInsurance}
            onChange={(value) => handleInputChange("healthInsurance", value)}
          />
          <GeneralInput
            id="file"
            type="file"
            label="Logo hochladen"
            onChange={(value) => handleInputChange("file", value)}
            preview={logoPreview}
          />
          <div className="text-center mt-6">
            <button
              type="submit"
              className="px-4 py-2 font-bold text-white bg-green-500 rounded-full hover:bg-blue-700 focus:outline-none focus:shadow-outline"
              disabled={isLoading}
            >
              {isLoading ? "Sende..." : "Senden"}
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

export default GeneralInfoForm;