import React, { useContext, useEffect } from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { FaRegListAlt, FaQuestionCircle } from "react-icons/fa";
import { BiQuestionMark } from "react-icons/bi";
import { UserContext } from "../context/UserContext";

const Dashboard = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useContext(UserContext);

  useEffect(() => {
    console.log("User role:", user?.role);
  }, [user]);

  const getButtonClasses = (path, baseClasses, activeClasses) => {
    return location.pathname === path
      ? `${baseClasses} ${activeClasses}`
      : baseClasses;
  };

  return (
    <div className="h-full bg-white mt-12">
      <div className="mx-auto">
        <div className="flex flex-col md:flex-row justify-center px-6 py-12">
          <div className="w-full md:w-3/4 lg:w-11/12 flex">
            <div className="mt-12 md:mt-0 flex flex-col bg-white rounded-xl border-gray-700 shadow-slate-700/[.7]">
              {user.role !== "admin" && (
                <button
                  onClick={() => navigate("general-info")}
                  className={getButtonClasses(
                    "/general-info",
                    "p-4 md:p-5 block max-w-[18rem] bg-green-100 text-green-900 rounded-md flex items-center",
                    "shadow-secondary-1 active:bg-green-200"
                  )}
                >
                  <FaRegListAlt className="text-lg mr-2" />
                  <h3 className="text-lg font-bold text-success-600">
                    Allgemeines{" "}
                  </h3>
                </button>
              )}
              {user.role !== "admin" && (
                <button
                  onClick={() => navigate("specific-questions")}
                  className={getButtonClasses(
                    "/specific-questions",
                    "mt-2 p-4 md:p-5 block max-w-[18rem] bg-red-100 text-red-900 border-success-600 rounded-md flex items-center",
                    "shadow-secondary-1 active:bg-red-200"
                  )}
                >
                  <BiQuestionMark className="text-lg mr-2" />
                  <h3 className="text-lg font-bold text-gray-800">
                    Serviceleistungen{" "}
                  </h3>
                </button>
              )}
              {user.role !== "admin" && (
                <button
                  onClick={() => navigate("application")}
                  className={getButtonClasses(
                    "/application",
                    "mt-2 p-4 md:p-5 block max-w-[18rem] border border-info-600 bg-white rounded-md flex items-center justify-center",
                    "bg-info-50 active:bg-info-100"
                  )}
                >
                  <FaQuestionCircle className="text-lg mr-2" />
                  <h3 className="text-lg font-bold text-gray-800">
                  Anträge
                  </h3>
                </button>
              )}
            </div>
            <div className="mt-12 md:mt-0 ml-4 w-full lg:w-12/12 bg-white shadow-sm border border-green-500 rounded-br-lg p-5 rounded-lg lg:rounded-l-none">
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
