import React from 'react';
import GeneralInput from '../../GeneralInput';
import SectionHeadline from '../ui/sectionHeadline';

const MedicineForm = ({ data, updateData }) => {
  const handleInputChange = (id, key, value) => {
    updateData(id, key, value);
  };

  return (
    <div className="p-4">
      <SectionHeadline sectionName="Osteopathy" />
      <GeneralInput
        id="osteopathy_1"
        type="conditional-radio-two"
        label="Bezuschuss durch Satzungsleistung"
        options={[
          { label: 'Ja', value: 'yes' },
          { label: 'Nein', value: 'no' },
        ]}
        value={data?.osteopathy_1?.value || ''}
        onChange={(value) => handleInputChange('osteopathy_1', 'value', value)}
        conditionalValues={{
          text1: {
            value: data?.osteopathy_1?.valueOption1 || '',
            label: 'Wenn ja, wie hoch?',
            placeholder: 'Höhe in € eingeben',
            onChange: (value) => handleInputChange('osteopathy_1', 'valueOption1', value),
          },
          text2: {
            value: data?.osteopathy_1?.valueOption2 || '',
            label: 'Wenn ja, wie oft?',
            placeholder: 'Häufigkeit in Monaten eingeben',
            onChange: (value) => handleInputChange('osteopathy_1', 'valueOption2', value),
          },
        }}
      />
      <GeneralInput
        id="osteopathy_2"
        type="conditional-radio-two"
        label="Bezuschussung durch Gesundheitskonto / Punkteprogramm"
        options={[
          { label: 'Ja', value: 'yes' },
          { label: 'Nein', value: 'no' },
        ]}
        value={data?.osteopathy_2?.value || ''}
        onChange={(value) => handleInputChange('osteopathy_2', 'value', value)}
        conditionalValues={{
          text1: {
            value: data?.osteopathy_2?.valueOption1 || '',
            label: 'Wenn ja, wie hoch?',
            placeholder: 'Höhe in € eingeben',
            onChange: (value) => handleInputChange('osteopathy_2', 'valueOption1', value),
          },
          text2: {
            value: data?.osteopathy_2?.valueOption2 || '',
            label: 'Wenn ja, wie oft?',
            placeholder: 'Häufigkeit in Monaten eingeben',
            onChange: (value) => handleInputChange('osteopathy_2', 'valueOption2', value),
          },
        }}
      />
      <GeneralInput
        id="osteopathy_3"
        type="textarea"
        label="Besondere Zusatzleistungen (Freitext)"
        placeholder="Enter details"
        value={data?.osteopathy_3?.value || ''}
        onChange={(value) => handleInputChange('osteopathy_3', 'value', value)}
      />

      <SectionHeadline sectionName="Homeopathy" />
      <GeneralInput
        id="homeopathy_1"
        type="conditional-radio-two"
        label="Bezuschuss durch Satzungsleistung"
        options={[
          { label: 'Ja', value: 'yes' },
          { label: 'Nein', value: 'no' },
        ]}
        value={data?.homeopathy_1?.value || ''}
        onChange={(value) => handleInputChange('homeopathy_1', 'value', value)}
        conditionalValues={{
          text1: {
            value: data?.homeopathy_1?.valueOption1 || '',
            label: 'Wenn ja, wie hoch?',
            placeholder: 'Höhe in € eingeben',
            onChange: (value) => handleInputChange('homeopathy_1', 'valueOption1', value),
          },
          text2: {
            value: data?.homeopathy_1?.valueOption2 || '',
            label: 'Wenn ja, wie oft?',
            placeholder: 'Häufigkeit in Monaten eingeben',
            onChange: (value) => handleInputChange('homeopathy_1', 'valueOption2', value),
          },
        }}
      />
      <GeneralInput
        id="homeopathy_2"
        type="conditional-radio-two"
        label="Bezuschussung durch Gesundheitskonto / Punkteprogramm"
        options={[
          { label: 'Ja', value: 'yes' },
          { label: 'Nein', value: 'no' },
        ]}
        value={data?.homeopathy_2?.value || ''}
        onChange={(value) => handleInputChange('homeopathy_2', 'value', value)}
        conditionalValues={{
          text1: {
            value: data?.homeopathy_2?.valueOption1 || '',
            label: 'Wenn ja, wie hoch?',
            placeholder: 'Höhe in € eingeben',
            onChange: (value) => handleInputChange('homeopathy_2', 'valueOption1', value),
          },
          text2: {
            value: data?.homeopathy_2?.valueOption2 || '',
            label: 'Wenn ja, wie oft?',
            placeholder: 'Häufigkeit in Monaten eingeben',
            onChange: (value) => handleInputChange('homeopathy_2', 'valueOption2', value),
          },
        }}
      />
      <GeneralInput
        id="homeopathy_3"
        type="textarea"
        label="Besondere Zusatzleistungen (Freitext)"
        placeholder="Enter details"
        value={data?.homeopathy_3?.value || ''}
        onChange={(value) => handleInputChange('homeopathy_3', 'value', value)}
      />

      <SectionHeadline sectionName="Acupuncture" />
      <GeneralInput
        id="acupuncture_1"
        type="conditional-radio-two"
        label="Bezuschuss durch Satzungsleistung"
        options={[
          { label: 'Ja', value: 'yes' },
          { label: 'Nein', value: 'no' },
        ]}
        value={data?.acupuncture_1?.value || ''}
        onChange={(value) => handleInputChange('acupuncture_1', 'value', value)}
        conditionalValues={{
          text1: {
            value: data?.acupuncture_1?.valueOption1 || '',
            label: 'Wenn ja, wie hoch?',
            placeholder: 'Höhe in € eingeben',
            onChange: (value) => handleInputChange('acupuncture_1', 'valueOption1', value),
          },
          text2: {
            value: data?.acupuncture_1?.valueOption2 || '',
            label: 'Wenn ja, wie oft?',
            placeholder: 'Häufigkeit in Monaten eingeben',
            onChange: (value) => handleInputChange('acupuncture_1', 'valueOption2', value),
          },
        }}
      />
      <GeneralInput
        id="acupuncture_2"
        type="conditional-radio-two"
        label="Bezuschussung durch Gesundheitskonto / Punkteprogramm"
        options={[
          { label: 'Ja', value: 'yes' },
          { label: 'Nein', value: 'no' },
        ]}
        value={data?.acupuncture_2?.value || ''}
        onChange={(value) => handleInputChange('acupuncture_2', 'value', value)}
        conditionalValues={{
          text1: {
            value: data?.acupuncture_2?.valueOption1 || '',
            label: 'Wenn ja, wie hoch?',
            placeholder: 'Höhe in € eingeben',
            onChange: (value) => handleInputChange('acupuncture_2', 'valueOption1', value),
          },
          text2: {
            value: data?.acupuncture_2?.valueOption2 || '',
            label: 'Wenn ja, wie oft?',
            placeholder: 'Häufigkeit in Monaten eingeben',
            onChange: (value) => handleInputChange('acupuncture_2', 'valueOption2', value),
          },
        }}
      />
      <GeneralInput
        id="acupuncture_3"
        type="textarea"
        label="Besondere Zusatzleistungen (Freitext)"
        placeholder="Enter details"
        value={data?.acupuncture_3?.value || ''}
        onChange={(value) => handleInputChange('acupuncture_3', 'value', value)}
      />

      <SectionHeadline sectionName="Homeopathic Medicine" />
      <GeneralInput
        id="homeopathicMedicine_1"
        type="conditional-radio-two"
        label="Bezuschuss durch Satzungsleistung"
        options={[
          { label: 'Ja', value: 'yes' },
          { label: 'Nein', value: 'no' },
        ]}
        value={data?.homeopathicMedicine_1?.value || ''}
        onChange={(value) => handleInputChange('homeopathicMedicine_1', 'value', value)}
        conditionalValues={{
          text1: {
            value: data?.homeopathicMedicine_1?.valueOption1 || '',
            label: 'Wenn ja, wie hoch?',
            placeholder: 'Höhe in € eingeben',
            onChange: (value) => handleInputChange('homeopathicMedicine_1', 'valueOption1', value),
          },
          text2: {
            value: data?.homeopathicMedicine_1?.valueOption2 || '',
            label: 'Wenn ja, wie oft?',
            placeholder: 'Häufigkeit in Monaten eingeben',
            onChange: (value) => handleInputChange('homeopathicMedicine_1', 'valueOption2', value),
          },
        }}
      />
      <GeneralInput
        id="homeopathicMedicine_2"
        type="conditional-radio-two"
        label="Bezuschussung durch Gesundheitskonto / Punkteprogramm"
        options={[
          { label: 'Ja', value: 'yes' },
          { label: 'Nein', value: 'no' },
        ]}
        value={data?.homeopathicMedicine_2?.value || ''}
        onChange={(value) => handleInputChange('homeopathicMedicine_2', 'value', value)}
        conditionalValues={{
          text1: {
            value: data?.homeopathicMedicine_2?.valueOption1 || '',
            label: 'Wenn ja, wie hoch?',
            placeholder: 'Höhe in € eingeben',
            onChange: (value) => handleInputChange('homeopathicMedicine_2', 'valueOption1', value),
          },
          text2: {
            value: data?.homeopathicMedicine_2?.valueOption2 || '',
            label: 'Wenn ja, wie oft?',
            placeholder: 'Häufigkeit in Monaten eingeben',
            onChange: (value) => handleInputChange('homeopathicMedicine_2', 'valueOption2', value),
          },
        }}
      />
      <GeneralInput
        id="homeopathicMedicine_3"
        type="textarea"
        label="Besondere Zusatzleistungen (Freitext)"
        placeholder="Enter details"
        value={data?.homeopathicMedicine_3?.value || ''}
        onChange={(value) => handleInputChange('homeopathicMedicine_3', 'value', value)}
      />
    </div>
  );
};

export default MedicineForm;
