import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { decodeToken } from 'react-jwt';

export const UserContext = createContext(null);

export const UserProvider = ({ children }) => {
  const navigate = useNavigate();
  const [user, setUser] = useState(() => {
    const storedUser = localStorage.getItem('user');
    return storedUser ? JSON.parse(storedUser) : null;
  });
  const [token, setToken] = useState(localStorage.getItem('token'));
  const [questionnaireData, setQuestionnaireData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    if (token) {
      const decoded = decodeToken(token);
      if (decoded && decoded.exp * 1000 > Date.now()) {
        setUser(prevUser => ({ ...prevUser, ...decoded }));
      } else {
        logout();
      }
    }
  }, [token]);

  useEffect(() => {
    if (user) {
      localStorage.setItem('user', JSON.stringify(user));
    }
  }, [user]);

  const fetchData = useCallback(async (userId) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/insurance/${userId}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      if (response.data && response.data.data) {
        const formattedData = response.data.data.sections.reduce((acc, section) => {
          acc[section.title.toLowerCase()] = section.questions.reduce((sectionAcc, question) => {
            sectionAcc[question.id] = {
              value: question.value || '',
              valueOption1: question.valueOption1 || '',
              valueOption2: question.valueOption2 || '',
              type: question.type || '' 
            };
            return sectionAcc;
          }, {});
          return acc;
        }, {});
        setQuestionnaireData(formattedData);
      } else {
        setError('No questionnaire data returned');
      }
    } catch (error) {
      setError("Failed to fetch data: " + error.message);
    }
  }, [token]);

  useEffect(() => {
    if (user && token) {
      fetchData(user.userId);
    }
  }, [user, token, fetchData]);

  const updateQuestionnaireData = (section, id, key, value) => {
    setQuestionnaireData((prevData) => ({
      ...prevData,
      [section]: {
        ...prevData[section],
        [id]: {
          ...prevData[section]?.[id],
          [key]: value,
        },
      },
    }));
  };

  const submitQuestionnaire = async (userId, data) => {
    try {
      await axios.put(`${process.env.REACT_APP_API_BASE_URL}/insurance/${userId}`, { sections: formatQuestionnaireData(data) }, {
        headers: { Authorization: `Bearer ${token}` }
      });
    } catch (error) {
      throw new Error("Failed to submit data: " + error.message);
    }
  };

  const formatQuestionnaireData = (data) => {
    return Object.keys(data).map(section => ({
      title: section,
      questions: Object.keys(data[section]).map(id => ({
        id,
        ...data[section][id],
        type: data[section][id].type || 'text' 
      })),
    }));
  };

  const login = async (formData) => {
    setIsLoading(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/login`, formData);
      if (response.data && response.data.token) {
        localStorage.setItem('token', response.data.token);
        setToken(response.data.token);
        const userData = {
          ...response.data.user,
          token: response.data.token
        };
        setUser(userData);
        navigate(userData.role === "company" ? "/dashboard/general-info" : "/dashboard/profile");
      } else {
        throw new Error("Token not received from server");
      }
    } catch (error) {
      console.error("Login error:", error);
      setError("Login failed: " + (error.response?.data?.message || error.message || "An error occurred"));
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    setUser(null);
    setToken(null);
    setQuestionnaireData({});
    navigate('/login', { replace: true });
  };

  return (
    <UserContext.Provider value={{
      user,
      updateUser: setUser,
      questionnaireData,
      updateQuestionnaireData,
      isLoading,
      setIsLoading,
      error,
      setError,
      login,
      logout,
      token,
      setToken,
      submitQuestionnaire
    }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);