import React from 'react';
import GeneralInput from '../../GeneralInput';
import SectionHeadline from '../ui/sectionHeadline';

const ArtificialForm = ({ data, updateData }) => {
  const handleInputChange = (id, key, value) => {
    updateData(id, key, value);
  };

  return (
    <div className="p-4">
      <SectionHeadline sectionName="Artificial Insemination" />
      <GeneralInput
        id="artificialInsemination_1"
        type="conditional-radio-two"
        label="Bezuschuss durch Satzungsleistung"
        options={[
          { label: 'Ja', value: 'yes' },
          { label: 'Nein', value: 'no' },
        ]}
        value={data?.artificialInsemination_1?.value || ''}
        onChange={(value) => handleInputChange('artificialInsemination_1', 'value', value)}
        conditionalValues={{
          text1: {
            value: data?.artificialInsemination_1?.valueOption1 || '',
            label: 'Wenn ja, wie hoch?',
            placeholder: 'Höhe in € eingeben',
            onChange: (value) => handleInputChange('artificialInsemination_1', 'valueOption1', value),
          },
          text2: {
            value: data?.artificialInsemination_1?.valueOption2 || '',
            label: 'Wenn ja, wie oft?',
            placeholder: 'Häufigkeit in Monaten eingeben',
            onChange: (value) => handleInputChange('artificialInsemination_1', 'valueOption2', value),
          },
        }}
      />
      <GeneralInput
        id="artificialInsemination_2"
        type="conditional-radio-two"
        label="Bezuschussung durch Gesundheitskonto / Punkteprogramm"
        options={[
          { label: 'Ja', value: 'yes' },
          { label: 'Nein', value: 'no' },
        ]}
        value={data?.artificialInsemination_2?.value || ''}
        onChange={(value) => handleInputChange('artificialInsemination_2', 'value', value)}
        conditionalValues={{
          text1: {
            value: data?.artificialInsemination_2?.valueOption1 || '',
            label: 'Wenn ja, wie hoch?',
            placeholder: 'Höhe in € eingeben',
            onChange: (value) => handleInputChange('artificialInsemination_2', 'valueOption1', value),
          },
          text2: {
            value: data?.artificialInsemination_2?.valueOption2 || '',
            label: 'Wenn ja, wie oft?',
            placeholder: 'Häufigkeit in Monaten eingeben',
            onChange: (value) => handleInputChange('artificialInsemination_2', 'valueOption2', value),
          },
        }}
      />
      <GeneralInput
        id="artificialInsemination_3"
        type="textarea"
        label="Besondere Zusatzleistungen (Freitext)"
        placeholder="Enter details"
        value={data?.artificialInsemination_3?.value || ''}
        onChange={(value) => handleInputChange('artificialInsemination_3', 'value', value)}
      />

      <SectionHeadline sectionName="Pregnancy Care" />
      <GeneralInput
        id="pregnancyCare_1"
        type="conditional-radio-two"
        label="Bezuschuss durch Satzungsleistung"
        options={[
          { label: 'Ja', value: 'yes' },
          { label: 'Nein', value: 'no' },
        ]}
        value={data?.pregnancyCare_1?.value || ''}
        onChange={(value) => handleInputChange('pregnancyCare_1', 'value', value)}
        conditionalValues={{
          text1: {
            value: data?.pregnancyCare_1?.valueOption1 || '',
            label: 'Wenn ja, wie hoch?',
            placeholder: 'Höhe in € eingeben',
            onChange: (value) => handleInputChange('pregnancyCare_1', 'valueOption1', value),
          },
          text2: {
            value: data?.pregnancyCare_1?.valueOption2 || '',
            label: 'Wenn ja, wie oft?',
            placeholder: 'Häufigkeit in Monaten eingeben',
            onChange: (value) => handleInputChange('pregnancyCare_1', 'valueOption2', value),
          },
        }}
      />
      <GeneralInput
        id="pregnancyCare_2"
        type="textarea"
        label="Besondere Zusatzleistungen (Freitext)"
        placeholder="Enter details"
        value={data?.pregnancyCare_2?.value || ''}
        onChange={(value) => handleInputChange('pregnancyCare_2', 'value', value)}
      />

<SectionHeadline sectionName="Kinder und Jugenduntersuchung" />
      <GeneralInput
        id="childrenInsepection_1"
        type="conditional-radio-two"
        label="Bezuschuss durch Satzungsleistung"
        options={[
          { label: 'Ja', value: 'yes' },
          { label: 'Nein', value: 'no' },
        ]}
        value={data?.childrenInsepection_1?.value || ''}
        onChange={(value) => handleInputChange('childrenInsepection_1', 'value', value)}
        conditionalValues={{
          text1: {
            value: data?.childrenInsepection_1?.valueOption1 || '',
            label: 'Wenn ja, wie hoch?',
            placeholder: 'Höhe in € eingeben',
            onChange: (value) => handleInputChange('childrenInsepection_1', 'valueOption1', value),
          },
          text2: {
            value: data?.childrenInsepection_1?.valueOption2 || '',
            label: 'Wenn ja, wie oft?',
            placeholder: 'Häufigkeit in Monaten eingeben',
            onChange: (value) => handleInputChange('childrenInsepection_1', 'valueOption2', value),
          },
        }}
      />
      <GeneralInput
        id="childrenInsepection_2"
        type="textarea"
        label="Besondere Zusatzleistungen (Freitext)"
        placeholder="Enter details"
        value={data?.childrenInsepection_2?.value || ''}
        onChange={(value) => handleInputChange('childrenInsepection_2', 'value', value)}
      />

      <SectionHeadline sectionName="Midwife Service" />
      <GeneralInput
        id="midWifeServices_1"
        type="conditional-radio-two"
        label="Bezuschuss durch Satzungsleistung"
        options={[
          { label: 'Ja', value: 'yes' },
          { label: 'Nein', value: 'no' },
        ]}
        value={data?.midWifeServices_1?.value || ''}
        onChange={(value) => handleInputChange('midWifeServices_1', 'value', value)}
        conditionalValues={{
          text1: {
            value: data?.midWifeServices_1?.valueOption1 || '',
            label: 'Wenn ja, wie hoch?',
            placeholder: 'Höhe in € eingeben',
            onChange: (value) => handleInputChange('midWifeServices_1', 'valueOption1', value),
          },
          text2: {
            value: data?.midWifeServices_1?.valueOption2 || '',
            label: 'Wenn ja, wie oft?',
            placeholder: 'Häufigkeit in Monaten eingeben',
            onChange: (value) => handleInputChange('midWifeServices_1', 'valueOption2', value),
          },
        }}
      />
      <GeneralInput
        id="midWifeServices_2"
        type="textarea"
        label="Besondere Zusatzleistungen (Freitext)"
        placeholder="Enter details"
        value={data?.midWifeServices_2?.value || ''}
        onChange={(value) => handleInputChange('midWifeServices_2', 'value', value)}
      />
      <GeneralInput
        id="midWifeServices_3"
        type="select"
        label="Vermittlung von Hebammen"
        options={[
          { label: 'Ja', value: 'yes' },
          { label: 'Nein', value: 'no' },
        ]}
        value={data?.midWifeServices_3?.value || ''}
        onChange={(value) => handleInputChange('midWifeServices_3', 'value', value)}
      />
    </div>
  );
};

export default ArtificialForm;
